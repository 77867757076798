:root {
    --active-color: #ce5c38;

    --f7-navbar-height: 57px;
    --f7-navbar-title-font-size: 17px;
    --f7-navbar-title-line-height: 22px;
    --f7-navbar-title-font-weight: 600;
    --f7-navbar-text-color: rgba(0, 0, 0, 0.85);

    --f7-navbar-bg-color: #fff;
    --f7-block-margin-vertical: 20px;
    --f7-list-margin-vertical: 20px;
    --f7-list-item-title-text-color: #707183;

    --f7-block-title-font-size: 17px;
    --f7-block-title-font-weight: 700;
    --f7-block-title-text-color: #333333;

    --f7-theme-color: #ce5c38;
    --swiper-theme-color: #ce5c38;

    --f7-messagebar-textarea-height: 36px;
    --f7-messagebar-textarea-border-radius: 18px;
    --f7-messagebar-textarea-bg-color: #f6f6f6;

    --f7-block-padding-horizontal: 13px;
    --f7-block-padding-vertical: 13px;

    --f7-input-height: 54px;

    /* notofocation */
    --f7-notification-title-color: var(--f7-theme-color);
    --f7-notification-title-font-weight: 600;
    --f7-notification-title-font-size: 17px;
    --f7-notification-title-text-transform: none;
    --f7-notification-padding: 14px;
    --f7-notification-text-font-size: 13px;
    --f7-notification-text-color: var(--f7-block-text-color);
}

/* iOS Cordova Tweak */
.device-cordova.device-ios {
    height: 100vh;
}

/* Your app custom styles here */

body {
    color: #333333;
}

body,
body * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

img,
a,
button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
}

textarea,
input {
    user-select: auto;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
}

a {
    text-decoration: none;
    color: #333333;
}

.fixed {
    position: fixed !important;
}

.svg_icon {
    display: block;
}

.svg_icon path {
    fill: var(--f7-theme-color);
}

.svg_icon circle {
    stroke: var(--f7-theme-color);
}

.svg_icon.svg_path_stroke_colored path {
    stroke: var(--f7-theme-color);
    fill: transparent;
}

.content-wrapper {
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.content-wrapper-index-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden;
}

.button-index a {
    background: var(--f7-theme-color);
    border-radius: 8px;
    border: none;
    color: #fff;
    padding: 14px 120px;
    display: inline-block;
}

.slideUp {
    animation-name: slideUp;
    -webkit-animation-name: slideUp;

    animation-duration: 4s;
    -webkit-animation-duration: 4s;

    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;

    visibility: visible !important;
    position: absolute;
    bottom: 2%;
}

.img-index {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    padding: 40px 0 60px 0;
    text-align: center;
}

.img-index img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: cover;
    transform: scale(1.7);
    animation: img-index 0.9s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    margin: 50px auto 0 auto;
    max-height: 790px;
    max-width: 512px;
}

@keyframes img-index {
    0% {
        transform: scaleX(1.7) scaleY(1.7);
    }

    100% {
        transform: scaleX(0) scaleY(0) translateY(-75px);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(200%);
    }

    50% {
        transform: translateY(-8%);
    }

    65% {
        transform: translateY(-6%);
    }

    80% {
        transform: translateY(-2%);
    }

    95% {
        transform: translateY(2%);
    }

    100% {
        transform: translateY(0%);
    }
}

@-webkit-keyframes slideUp {
    0% {
        transform: translateY(200%);
    }

    50% {
        transform: translateY(-8%);
    }

    65% {
        transform: translateY(-6%);
    }

    80% {
        transform: translateY(-2%);
    }

    95% {
        transform: translateY(2%);
    }

    100% {
        transform: translateY(0%);
    }
}

.modal-popup {
    display: none;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: 10151;
}

.modal-popup .modal-bg {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    position: fixed;
    top: 0;
    bottom: 0;
}

.modal-popup .modal-block {
    width: 324px;
    max-width: 100%;
    z-index: 10152;
    background: #fff;
    color: #6d7276;
    box-sizing: border-box;
    margin: auto;
    position: relative;
    padding: 52px;
    box-shadow:
        0 8px 22px -6px rgba(24, 39, 75, 0.12),
        0 14px 64px -4px rgba(24, 39, 75, 0.12);
    border-radius: 17px;
}

.modal-popup .modal-block .modal-img {
    width: 220px;
    height: 220px;
}

.modal-popup .modal-block .modal-img img {
    width: 100%;
    height: 100%;
    display: block;
}

.modal-popup .modal-block .modal-close-link-img {
    position: absolute;
    top: 19px;
    right: 19px;
}

.modal-popup .modal-block .modal-close-link-img img {
    background: rgba(129, 140, 153, 0.12);
    padding: 7px;
    box-sizing: border-box;
    border-radius: 15px;
}

section.header-chat {
    position: fixed;
    width: 100%;
    z-index: 1000;
}

section.header-chat .header-title {
    background: #fff;
}

section.header .header-title,
section.header-chat .header-title {
    display: flex;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

section.header .header-title .title {
    font-weight: 600;
    font-size: 21px;
    width: 100%;
    text-align: center;
}

section.header .header-title .title-shop {
    width: 90%;
    padding-left: 35px;
}

section.header .header-title a {
    text-align: right;
    width: 10%;
    position: relative;
}

section.header .header-title .back-img {
    width: 24px;
    height: 24px;
}

section.header .header-title a i {
    color: var(--f7-theme-color);
    font-size: 22px;
}

section.header .header-title a .shopping-cart {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -10px;
    right: -9px;
    width: 18px;
    height: 18px;
    background: #ff3347;
    border-radius: 18px;
    font-size: 12px;
    color: #fff;
}

section.shop {
    padding: 16px 20px;
    box-sizing: border-box;
}

section.shop .shop-block {
    display: flex;
}

section.shop .shop-block .shop-block-item {
    width: 50%;
    background: #fff;
    text-align: center;
}

section.shop .shop-block .shop-block-item .img-shop img {
    padding: 10px 6px;
    box-sizing: border-box;
    object-fit: contain;
    width: 167px;
    height: 214px;
    display: block;
}

section.shop .shop-block .shop-block-item:nth-child(2n + 1) {
    border-right: 1px solid #f2f2f2;
}

section.shop .shop-block .shop-block-item .name {
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    text-transform: uppercase;
    padding: 8px 0 10px 0;
    box-sizing: border-box;
}

section.shop .shop-block .shop-block-item .description {
    font-size: 12px;
    line-height: 14px;
    color: #707183;
    padding-bottom: 10px;
    box-sizing: border-box;
}

section.shop .shop-block .shop-block-item .prise {
    font-size: 15px;
    line-height: 18px;
    color: #000000;
    padding-bottom: 15px;
    box-sizing: border-box;
}

section.shop .shop-block .shop-block-item .prise i {
    color: #000;
    font-size: 15px;
}

section.shop .shop-block .shop-block-item .shop-button {
    text-align: center;
    padding-bottom: 20px;
    box-sizing: border-box;
}

section.shop .shop-block .shop-block-item .shop-button a {
    display: inline-block;
    background: var(--f7-theme-color);
    border-radius: 5px;
    padding: 10px 29px;
    box-sizing: border-box;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
}

section.shop .shop-block .shop-block-item .owl-theme .owl-dots .owl-dot span {
    width: 7px;
    height: 7px;
    margin: 5px 3px;
}

section.shop .shop-block .shop-block-item .owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 0;
}

section.basket-shop {
    padding: 16px 10px 102px 10px;
    box-sizing: border-box;
}

section.basket-shop .card-product {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 50px;
}

section.basket-shop .card-product .img,
section.basket-shop .card-product .card-product-description,
section.basket-shop .card-product .quantity,
section.basket-shop .card-product .delete-button {
    width: 50%;
}

section.basket-shop .card-product .quantity,
section.basket-shop .card-product .delete-button {
    padding-top: 10px;
    box-sizing: border-box;
    font-size: 15px;
    color: #707183;
}

section.basket-shop .card-product .delete-button a {
    display: flex;
    align-items: center;
}

section.basket-shop .card-product .quantity a img,
section.basket-shop .card-product .delete-button a img {
    margin-left: 6px;
}

section.basket-shop .card-product .img,
section.basket-shop .card-product .card-product-description {
    border-bottom: 1px solid #f6f6f6;
}

section.basket-shop .card-product .img img {
    display: block;
    width: 125px;
    height: 161px;
    object-fit: contain;
}

section.basket-shop .card-product .card-product-description .name {
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
    padding: 10px 0;
    box-sizing: border-box;
}

section.basket-shop .card-product .card-product-description .description {
    font-size: 12px;
    line-height: 14px;
    color: #707183;
    padding-bottom: 50px;
    box-sizing: border-box;
}

section.basket-shop .card-product .card-product-description .prise {
    font-size: 22px;
    line-height: 26px;
}

section.basket-shop .basket-shop-block .basket-shop-description {
    display: flex;
    flex-wrap: wrap;
}

section.basket-shop .basket-shop-block .basket-shop-description .col-1,
section.basket-shop .basket-shop-block .basket-shop-description .col-2,
section.basket-shop .basket-shop-block .basket-shop-description .col-3,
section.basket-shop .basket-shop-block .basket-shop-description .col-4 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;
    box-sizing: border-box;
}

section.basket-shop .basket-shop-block .basket-shop-description .col-4 {
    padding-bottom: 50px;
}

section.basket-shop .basket-shop-block .basket-shop-description .title-basket {
    font-weight: 600;
    font-size: 18px;
}

section.basket-shop .basket-shop-block .basket-shop-description .quantity,
section.basket-shop .basket-shop-block .basket-shop-description .discount {
    font-size: 17px;
    line-height: 22px;
}

section.basket-shop .basket-shop-block .basket-shop-description .total-prise,
section.basket-shop .basket-shop-block .basket-shop-description .prise {
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
}

section.basket-shop .basket-shop-block .basket-shop-description .basket-quantity {
    font-size: 12px;
}

section.basket-shop .basket-shop-block .basket-shop-description .quantity-prise,
section.basket-shop .basket-shop-block .basket-shop-description .discount-prise {
    font-size: 15px;
}

section.basket-shop .basket-shop-block .basket-shop-description .discount-prise {
    color: #fe3f3f;
}

section.basket-shop .basket-shop-block .basket-shop-description .buy-button {
    width: 100%;
}

section.basket-shop .basket-shop-block .basket-shop-description .buy-button a {
    padding: 14px 0;
    text-align: center;
    background: var(--f7-theme-color);
    border-radius: 5px;
    width: 100%;
    color: #fff;
    display: block;
}

section.empty-basket-shop .empty-basket-shop-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 65px;
    box-sizing: border-box;
}

section.empty-basket-shop .empty-basket-shop-block .title-empty-basket-shop {
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    box-sizing: border-box;
    padding: 230px 0 15px 0;
}

section.empty-basket-shop .empty-basket-shop-block .description-empty-basket-shop {
    font-size: 16px;
    line-height: 22px;
    color: #707183;
    padding-bottom: 25px;
    box-sizing: border-box;
    text-align: center;
}

section.empty-basket-shop .empty-basket-shop-block .button-empty-basket-shop {
    text-align: center;
}

section.empty-basket-shop .empty-basket-shop-block .button-empty-basket-shop a {
    padding: 10px 29px;
    box-sizing: border-box;
    background: var(--f7-theme-color);
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
}

section.quantity-goods {
    transform: translateY(250%);
    transition: all 0.5s;
    background: #fff;
    border-radius: 14px 14px 0 0;
    padding-bottom: 40px;
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 2000;
    box-shadow: 15px -60px 200px 100px rgba(0, 0, 0, 0.3);
}

section.quantity-goods-block {
    transform: none;
    transition: all 0.5s;
}

section.quantity-goods .quantity-goods-block .title-quantity-goods {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 16px 35px 16px;
    box-sizing: border-box;
}

section.quantity-goods .quantity-goods-block .title-quantity-goods .name {
    width: 90%;
    text-align: center;
    font-weight: 600;
    font-size: 21px;
    padding-left: 34px;
}

section.quantity-goods .quantity-goods-block .title-quantity-goods a {
    width: 10%;
}

section.quantity-goods .quantity-goods-block .col-quantity-goods {
    padding: 8px 20px;
    font-size: 18px;
    box-sizing: border-box;
    border-bottom: 1px solid #f6f6f6;
}

section.room-management {
    padding: 13px;
    box-sizing: border-box;
}

.open-block {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 17px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 12px;
    padding: 16px;
}

.open-block .open-block-left {
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid #e3e3e3;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: flex-start;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: rgba(0, 0, 0, 0.85);
}

.open-block .open-block-right {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.85);
}

.open-block .open-block-right span {
    color: var(--f7-theme-color);
}

.service-block-wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
}

.service-block {
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 17px;
    box-sizing: border-box;
    margin-bottom: 12px;
    overflow: hidden;
}

.service-block:last-child {
    margin-bottom: 0;
}

.service-block-half {
    width: calc(50% - 11px / 2);
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    height: 165px;
    justify-content: space-between;
}

.service-block .service-block-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 16px;
}

.service-block-half .service-block-content {
    flex-direction: column;
    height: 100%;
}

.service-block-content.light {
    padding-bottom: 0;
}

.service-block-content.conditioner {
    padding-bottom: 0;
}

.service-block .left {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.service-block .content {
    width: 100%;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
}

.service-block-half .content {
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.32px;
}

.service-block-half .content {
    justify-content: center;
}

.service-block .content .content-open-left {
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid #e3e3e3;
}

.service-block .content .content-open-right {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}

.service-block .content .content-open-right span {
    color: var(--f7-theme-color);
}

.service-block .right {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.service-block .service-block-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.service-block-half .service-block-buttons {
    flex-basis: 0;
}

.service-block .service-block-buttons .service-button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 45px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgba(112, 113, 131, 0.8);
    border-top: 1px solid #e3e3e3;
    width: 100%;
    position: relative;
}

.service-block .service-block-buttons .service-button:active {
    opacity: 0.7;
}

.service-block .service-block-buttons .service-button.half-button {
    width: 50%;
}

.service-block .service-block-buttons .service-button.on {
    background: var(--f7-theme-color);
    border-top: 1px solid var(--f7-theme-color);
    color: #fff;
}

.service-block .service-block-content.temperature .minus,
.service-block .service-block-content.temperature .plus {
    background: #F2F2F2;
    border-radius: 17px 0 0 17px;
    width: 67px;
    /* height: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 30px;
    color: #000;
    flex-shrink: 0;
}

.service-block .service-block-content.temperature .plus {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.service-block .service-block-content.temperature .minus:active,
.service-block .service-block-content.temperature .plus:active {
    background: #1c2f30;
    color: #fff;
}

.service-block .service-block-content.temperature .result {
    color: #000;
    font-weight: 300;
    font-size: 30px;
    width: 100%;
    text-align: center;
}

.tv-block {
    box-sizing: border-box;
    padding: 10px 30px;
}

.tv-block .tv-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.tv-block .numbers-row {
    margin-bottom: 27px;
}

.tv-block .tv-row:last-child {
    margin-bottom: 0;
}

.tv-block button {
    background: white;
    border: 1px solid #e3e3e3;
    width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.352px;
    color: #1c2f30;
    border-radius: 50%;
}

.tv-block button:active {
    opacity: 0.7;
}

.tv-block button.red {
    background: #ff3b30;
    border-color: #ff3b30;
    color: #fff;
}

.tv-block .dark {
    background: #1c2f30;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 65px;
    height: 125px;
}

.tv-block .dark button {
    background: transparent;
    border: none;
    width: 100%;
    height: 50px;
}

.tv-block .tv-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 32.5px;
    width: 100px;
    height: 65px;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078px;
    color: #1c2f30;
    box-sizing: border-box;
}

.humidifier-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 17px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 16px;
}

.humidifier-slider {
    margin-bottom: 70px;
}

.humidifier-slider .rs-handle {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    padding: 15px;
    margin: -19px 0 0 -5px !important;
}

.humidifier-slider .rs-tooltip-text {
    font-weight: 300;
    font-size: 36px;
    line-height: 43px;
    letter-spacing: -0.078px;
    color: #000000;
}

.humidifier-block .info {
    background: #f2f2f2;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 29px;
    padding: 16px 28px;
    display: flex;
    align-items: center;
    margin-bottom: 70px;
}

.humidifier-block .buttons {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.humidifier-block .buttons .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 22%;
}

.humidifier-block .buttons .item button {
    border: 1px solid #e3e3e3;
    background: #fff;
    width: 54px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.humidifier-block .buttons .item div {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #858585;
}

.lent-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 17px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 16px;
}

.lent-block .lent-wheel {
    margin-bottom: 70px;
}

.lent-block .lent-wheel .IroWheel {
    margin-bottom: 70px;
}

.lent-block .buttons {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.lent-block .buttons button {
    width: 49px;
    height: 49px;
    border-radius: 50%;
    border: none;
    background: transparent;
}

.lent-block .buttons button.active {
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.37);
}

section.room-management .shine-block,
section.room-management .air-conditioning-block {
    background: #ffffff;
    box-sizing: border-box;
    box-shadow:
        0 8px 22px -6px rgba(24, 39, 75, 0.12),
        0 14px 64px -4px rgba(24, 39, 75, 0.12);
    border-radius: 17px;
    padding-top: 20px;
    margin-bottom: 20px;
}

section.room-management .shine-block .title-shine,
section.room-management .air-conditioning-block .title-air-conditioning {
    font-size: 22px;
    line-height: 26px;
    color: #1c2f30;
    text-align: center;
    padding-bottom: 15px;
    box-sizing: border-box;
}

section.room-management .shine-block .button-shine,
section.room-management .air-conditioning-block .button-air-conditioning {
    width: 100%;
    display: flex;
}

section.room-management .shine-block .button-shine a,
section.room-management .air-conditioning-block .button-air-conditioning a {
    display: block;
    width: 50%;
    text-align: center;
    padding: 13px 0;
    box-sizing: border-box;
}

section.room-management .shine-block .button-shine .on,
section.room-management .air-conditioning-block .button-air-conditioning .on {
    border-radius: 0 0 0 17px;
    background: var(--f7-theme-color);
    color: #fff;
}

section.room-management .shine-block .button-shine .off,
section.room-management .air-conditioning-block .button-air-conditioning .off {
    border-radius: 0 0 17px 0;
    background: var(--f7-theme-color);
    color: #fff;
    margin-left: 1px;
}

section.room-management .shine-block .button-shine .on:active,
section.room-management .air-conditioning-block .button-air-conditioning .on:active,
section.room-management .shine-block .button-shine .off:active,
section.room-management .air-conditioning-block .button-air-conditioning .off:active {
    color: #fff;
    opacity: 0.7;
}

section.room-management .shine-block .slider-shine {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 28px;
}

section.room-management .shine-block .slider-shine #slider {
    width: 70%;
    margin: 0 13px;
    /* height: 2px;*/
    background: rgba(120, 120, 128, 0.2);
    border-radius: 3px;
    border: none;
}

section.room-management .shine-block i.icon {
    color: #cdcdcd;
}

section.room-management .shine-block .list {
    margin: 0 0 15px 0;
}

section.room-management .shine-block .list ul:before,
section.room-management .shine-block .list ul:after {
    content: none;
}

.ui-slider-horizontal {
    height: 2px;
}

.ui-widget-header {
    background: var(--f7-theme-color);
    border-radius: 3px;
}

.ui-state-default,
.ui-widget-content .ui-state-default {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow:
        0 3px 8px rgba(0, 0, 0, 0.15),
        0 1px 1px rgba(0, 0, 0, 0.16),
        0 3px 1px rgba(0, 0, 0, 0.1);
}

.ui-slider .ui-slider-handle {
    width: 28px;
    height: 28px;
    border-radius: 28px;
}

.ui-slider-horizontal .ui-slider-handle {
    top: -0.8em;
}

section.room-management .air-conditioning-block .temperature {
    padding: 43px 29px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

section.room-management .air-conditioning-block .temperature .minus,
section.room-management .air-conditioning-block .temperature .plus {
    background: #dddee2;
    border-radius: 17px 0 0 17px;
    width: 67px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 34px;
    color: #000;
}

section.room-management .air-conditioning-block .temperature .minus:active,
section.room-management .air-conditioning-block .temperature .plus:active {
    background: #1c2f30;
    color: #fff;
}

section.room-management .air-conditioning-block .temperature .plus {
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

section.room-management .air-conditioning-block .temperature .result {
    color: #000;
    font-weight: 300;
    font-size: 34px;
    padding: 0 47px;
    width: 47px;
}

.booking-page {
    padding: 0;
}

section.form-index {
    flex-wrap: wrap;
    width: 100%;
}

section.form-index>img {
    max-height: calc(100vh - 300px);
    display: block;
}

section.form-index .booking-form-description {
    font-weight: 600;
    font-size: 28px;
    line-height: 33px;
    color: #333333;
    text-align: center;
    margin-bottom: 25px;
    width: 100%;
    padding: 0 16px;
}

section.form-index form {
    box-sizing: border-box;
    text-align: center;
    width: calc(100% - 32px);
}

section.form-index form .item-input-wrap {
    max-width: 382px;
    margin: 0 auto 25px;
}

section.form-index form input[type='number'] {
    width: 100%;
    background: #fff;
    border: none;
    height: 58px;
    text-align: center;
    box-sizing: border-box;
    color: #707183;
    font-size: 20px;
    border-radius: 5px;
}

section.form-index form .form-button {
    display: block;
    background: var(--f7-theme-color);
    border-radius: 8px;
    border: none;
    color: #fff;
    width: 285px;
    max-width: 75%;
    height: 50px;
    line-height: 50px;
    margin: 0 auto;
}

section.chat {
    padding: 53px 16px 0;
    box-sizing: border-box;
    position: relative;
}

section.chat .chat-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(100vh - 163px);
    padding-bottom: 163px;
}

section.chat .comments-block {
    min-height: calc(100vh - 132px);
    margin-bottom: 83px;
    padding-bottom: 0;
}

section.chat .chat-block .one,
section.chat .chat-block .two {
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 140%;
    background: #fff;
    border-radius: 17px;
    position: relative;
    width: 313px;
    margin-top: 20px;
}

section.chat .chat-block .two {
    background: #ebebeb;
    width: 198px;
    align-self: flex-end;
}

section.chat .chat-block .application-block {
    padding-top: 20px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow:
        0 6px 8px -6px rgba(24, 39, 75, 0.12),
        0 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 17px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

section.chat .chat-block .application-block .status,
section.chat .chat-block .application-block .date {
    color: #707183;
    font-size: 15px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

section.chat .chat-block .application-block .status {
    color: var(--f7-theme-color);
    padding-right: 46px;
    padding-left: 20px;
}

section.chat .chat-block .application-block .description {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
}

section.chat .chat-block .application-block .button-application {
    margin: 0 auto;
}

section.chat .chat-block .application-block .button-application a {
    padding: 13px 69px;
    box-sizing: border-box;
    background: #f6f6f6;
    border-radius: 17px;
    font-size: 15px;
    line-height: 18px;
    color: #707183;
    display: inline-block;
    margin-bottom: 20px;
}

section.chat .chat-block .application-block .comments {
    background: var(--f7-theme-color);
    border-radius: 0 0 17px 17px;
    width: 100%;
    color: #fff;
    text-align: center;
    padding: 15px 0;
    box-sizing: border-box;
}

section.chat .chat-block .application-block .star-block {
    display: flex;
    padding: 0 20px 22px 20px;
    box-sizing: border-box;
}

section.chat .chat-block .application-block .star-block img {
    margin-right: 15px;
}

section.chat .chat-block .prompt-block {
    margin-top: 20px;
}

section.chat .chat-block .prompt-block .prompt {
    padding: 10px 20px;
    box-sizing: border-box;
    display: block;
    border: 1px solid #707183;
    border-radius: 50px;
    color: #707183;
    font-size: 18px;
    margin-right: 10px;
    white-space: nowrap;
}

section.top-menu {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 1000;
}

section.top-menu .top-menu-block {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 9px 0 9px 0;
    box-sizing: border-box;
}

section.top-menu .top-menu-block a {
    width: 33%;
}

section.top-menu .top-menu-block a .top-menu-block-item {
    display: inline-block;
    position: relative;
    color: #707183;
}

section.top-menu .top-menu-block a .active {
    color: var(--f7-theme-color);
}

section.top-menu .top-menu-block a .top-menu-block-item .img {
    padding-bottom: 5px;
    box-sizing: border-box;
    position: relative;
}

section.top-menu .top-menu-block a .top-menu-block-item .notification {
    width: 18px;
    height: 18px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #fe3f3f;
    font-size: 12px;
    color: #fff;
    top: -4px;
    right: -7px;
}

section.top-menu .message-block {
    display: flex;
    align-items: center;
    padding: 8px 16px 17px 16px;
    box-sizing: border-box;
}

section.top-menu .message-block .img-scan {
    margin-right: 12px;
}

section.top-menu .message-block form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

section.top-menu .message-block form a {
    height: 36px;
}

section.top-menu .message-block form input[type='text'] {
    background: #f6f6f6;
    box-sizing: border-box;
    border-radius: 18px;
    border: none;
    padding: 7px 12px;
    font-size: 17px;
    line-height: 22px;
    margin-right: 8px;
    width: 90%;
    color: #818c99;
}

.color-orange {
    --swiper-theme-color: var(--f7-theme-color);
    --f7-theme-color: var(--f7-theme-color);
    --f7-theme-color-rgb: 237, 105, 71;
    --f7-theme-color-shade: var(--f7-theme-color);
    --f7-theme-color-tint: var(--f7-theme-color);
}

.information-page {
    --f7-block-title-font-size: 13px;
    --f7-block-title-text-color: #707183;
    --f7-block-title-font-weight: normal;
}

.ios {
    --f7-font-family: SF Pro Text, -apple-system, SF UI Text, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif,
        Helvetica Neue, Helvetica, Arial, sans-serif;
}

.block {
    line-height: 1.5;
    --f7-block-font-size: 17px;
}

/*toolbar custom*/

.toolbar .toolbar-inner .tab-link .icon {
    display: block;
    width: 28px;
    height: 28px;
    background-color: #707183;
    mask-repeat: no-repeat;
    mask-position: center;
}

.toolbar .toolbar-inner .tab-link.tab-link-active .icon {
    background-color: var(--active-color);
}

.tabbar .tab-link-active,
.tabbar-labels .tab-link-active {
    color: var(--active-color);
}

.tabbar-labels .tabbar-label {
    font-size: 10px;
}

.toolbar .toolbar-inner .tab-link .icon.icon-home {
    -webkit-mask-image: url('../static/menu/home.svg');
    mask-image: url('../static/menu/home.svg');
}

.toolbar .toolbar-inner .tab-link .icon.icon-chat {
    -webkit-mask-image: url('../static/menu/chat.svg');
    mask-image: url('../static/menu/chat.svg');
}

.toolbar .toolbar-inner .tab-link .icon.icon-food {
    -webkit-mask-image: url('../static/menu/food.svg');
    mask-image: url('../static/menu/food.svg');
}

.toolbar .toolbar-inner .tab-link .icon.icon-shop {
    -webkit-mask-image: url('../static/menu/shop.svg');
    mask-image: url('../static/menu/shop.svg');
}

.toolbar .toolbar-inner .tab-link .icon.icon-information {
    -webkit-mask-image: url('../static/menu/information.svg');
    mask-image: url('../static/menu/information.svg');
}

/*navbar custom*/

.ios-translucent-bars .navbar-bg {
    background-color: #fff;
}

.navbar-bg:after,
.navbar-bg:before {
    display: none;
}

.navbar a.link svg {
    padding: 10px;
    display: block;
}

.height-100 {
    height: 100%;
}

.background-dark-green {
    background-color: #1c2f30;
    color: #fff;
}

.about-page .content-title {
    font-family: 'New York Extra Large', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 150%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.about-page .content-description {
    font-family: 'New York Extra Large', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 150%;
    color: white;
}

.about-block-info {
    margin: 0;
    overflow: auto;
    position: relative;
}

.about-block-info img {
    position: absolute;
    top: 0px;
}

.about-block-info::-webkit-scrollbar {
    width: 0;
    display: none;
}

#about-link {
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    word-wrap: break-word;
}

.img-pad {
    margin-top: -70px;
    height: 70px;
    flex-wrap: wrap;
    padding-top: 12px;
    padding-bottom: 12px;
}

.img-pad .big-text {
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
}

.img-pad .small-text {
    font-weight: 300;
    font-size: 13px;
    line-height: 150%;
}

.blur-block {
    color: #fff;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #333333 100%);
    backdrop-filter: blur(10px);
}

.margin-right-10 {
    margin-right: 10px;
}

.large-text {
    font-weight: 600;
    font-size: 21px;
    line-height: 150%;
}

.wifi-block {
    margin: 0 20px;
}

.wifi-block .title {
    font-size: 17px;
    line-height: 150%;
    color: #707183;
}

.wifi-block .description {
    font-size: 22px;
    line-height: 150%;
    color: #333333;
}

.ios .messagebar-area {
    margin: 8px 0;
}

.margin-top-bottom8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.ios-translucent-bars .messagebar {
    background: #fff;
    backdrop-filter: unset;
}

.ios .messagebar a.link.icon-only:last-child,
.messagebar a.link.icon-only:last-child {
    margin: 0 0 4px 0;
}

.messages>div:first-child {
    margin-top: auto;
}

.messages-content {
    padding-bottom: 52px;
}

/* дополнительные свойства */
.color-vertical-orange {
    color: var(--f7-theme-color);
}

.color-vertical-blue {
    color: #3f8cfe;
}

.font-size-vertical-small {
    font-size: small;
}

.item-text.hdtxt {
    color: black;
    font-weight: bold;
}

.fck_msg .notrnsfrm {
    text-transform: none;
    font-weight: normal;
}

.page.bg-color-vertical-gray {
    background-color: #f5f5f5;
}

.information-page .list .item-media {
    width: 15px;
}

/* дополнительные свойства КОНЕЦ --> */

.message.order-message,
.message.order-message .message-content,
.message.order-message .message-bubble {
    width: 100%;
}

.message.order-message .order-comment-link {
    display: block;
    margin: 0 calc(-1 * var(--f7-message-bubble-padding-horizontal)) calc(-1 * var(--f7-message-bubble-padding-vertical));
    background-color: var(--f7-theme-color);
    color: #ffffff;
    padding: 15px;
    box-sizing: border-box;
    text-align: center;
}

.ios .message.order-message .order-comment-link {
    margin-left: calc(-1 * var(--f7-message-bubble-padding-horizontal) - 6px);
}

.md .message-received.message-tail.order-message .message-bubble:before {
    /*border-bottom-color: var(--f7-theme-color);*/
    right: 99%;
}

.message.order-message .order-status-bar {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: #707183;
    margin-bottom: 20px;
    margin-top: calc(20px - var(--f7-message-bubble-padding-vertical));
}

.message.order-message .order-status-bar .status {
    color: var(--f7-theme-color);
}

.message.order-message .order-status-bar .status.red {
    color: #fe3f3f;
}

.message.order-message .order-status-bar .status.blue {
    color: #3f8cfe;
}

.message.order-message .order-status-bar .status.green {
    color: #07ae4a;
}

.message.order-message h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    margin: 0 0 20px;
}

.message.order-message .order-btn {
    width: 100%;
    background: #f6f6f6;
    border-radius: 17px;
    border: none;
    height: 44px;
    margin: 0 0 20px;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.24px;
    color: #707183;
    font-family: var(--f7-font-family);
}

.message.order-message .order-btn .timer {
    margin-right: 10px;
}

.text-uppercase {
    text-transform: uppercase;
}

.button-custom {
    height: 50px;
    text-transform: initial;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    border-radius: 5px;
}

.review-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 375px;
    width: 100%;
    margin: 0 auto;
}

.review-block label {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.review-block input[type='radio']:checked~svg {
    margin-bottom: 10px;
    margin-top: 10px;
}

.review-block input[type='radio']:checked~svg path {
    fill: var(--f7-theme-color);
}

.review-tooltip {
    color: var(--f7-theme-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.review-textarea {
    width: 100%;
    background: rgb(246, 246, 246);
    border: 1px solid rgba(112, 113, 131, 0.1);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    min-height: 103px;
}

.display_none {
    display: none;
}

#feedback-block {
    display: none;
}

#feedback-result {
    display: none;
}

.feedback-result .feedback-result-text {
    display: none;
}

.photo-browser-page-dark .navbar a {
    color: var(--active-color);
}

.photo-browser-page-dark .toolbar a i {
    color: var(--active-color);
}

.photo-browser-page-dark .toolbar {
    background-color: black !important;
}

.photo-browser-page-dark .navbar div {
    background-color: black !important;
}

#food-navbar .navbar-inner {
    height: 100%;
}

#food-navbar .title {
    margin-top: 20px;
}

#food-navbar div button {
    text-transform: uppercase;
    font-size: 14px;
}

#shop-navbar-title {
    margin-top: 2px;
}

.feedback-result .content-title {
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    letter-spacing: -0.408px;
    margin-bottom: 16px;
}

.feedback-result .content-description {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.078px;
}

.feedback-result .button-custom {
    margin-top: 20px;
}

#shop-toolbar-height {
    height: 46px;
    border: none;
}

#shop-toolbar-height::after {
    content: '';
    height: 0;
}

.food-page-navbar::before {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    top: 100%;
    bottom: auto;
    height: 8px;
    pointer-events: none;
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0.25) 0%,
            rgba(0, 0, 0, 0.08) 40%,
            rgba(0, 0, 0, 0.04) 50%,
            rgba(0, 0, 0, 0) 90%,
            rgba(0, 0, 0, 0) 100%);
}

.food-page-navbar,
.navbar-with-categories {
    height: calc(var(--f7-navbar-height) + var(--f7-safe-area-top) + 44px);
}

.food-page-navbar .navbar-inner,
.navbar-with-categories .navbar-inner {
    top: calc(-62px / 2);
}

.navbar-food,
.categories-navigation {
    overflow-y: auto;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
    margin-bottom: 0;
    z-index: 10;
    scroll-behavior: smooth;
}

.navbar-food::-webkit-scrollbar {
    width: 0;
    display: none;
}

.categories-navigation {
    margin-top: 0;
    position: sticky;
    top: 0;
}

.navbar-food:before,
.categories-navigation:before {
    height: 0;
}

.navbar-food button,
.categories-navigation button {
    width: auto;
    flex: 1 0 auto;
    text-transform: inherit;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4) !important;
    padding: 5px 10px;
    box-sizing: border-box;
    border: none;
    text-transform: var(--f7-tabbar-link-text-transform);
    font-weight: var(--f7-tabbar-link-font-weight);
    letter-spacing: var(--f7-tabbar-link-letter-spacing);
    height: auto;
}

.navbar-food button.button-fill,
.categories-navigation button.button-fill {
    background-color: rgba(206, 92, 56, 0.2);
    border-radius: 17px;
    color: var(--active-color) !important;
}

.navbar-food button.active-state,
.categories-navigation button.active-state {
    background: var(--active-color);
    color: #ffffff;
}

#food-content::-webkit-scrollbar {
    width: 0;
    display: none;
}

.food-category,
.content-with-categories {
    margin-top: calc(var(--f7-block-margin-vertical) + 35px);
}

.food-category .food-category-title {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.8px;
    color: #000000;
    padding: 12px 0;
    margin: 0;
}

.food-item:before,
.food-item:after {
    height: 0;
}

.food-item {
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 17px;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;
}

.food-item:last-child {
    margin-bottom: 0;
}

.food-item .left {
    width: 130px;
    height: 110px;
    border-radius: 10px;
    overflow: hidden;
}

.food-item .left .img {
    width: 100%;
    height: 100%;
}

.food-item .left .img img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.food-item .right {
    width: calc(100% - 147px);
    height: 110px;
    box-sizing: border-box;
    padding: 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.food-item .right .name {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #0a191e;
    margin-bottom: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.food-item .right .info {
    font-size: 13px;
    line-height: 16px;
    margin: 8px 0;
}

.food-item .right .info .gray {
    color: #959595;
}

.food-item .stepper-button-minus,
.food-item .stepper-button-plus {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background: #f5f5f5;
}

.food-item .stepper-button-plus:after,
.food-item .stepper-button-minus:after,
.food-item .stepper-button-plus:before,
.food-item .stepper-button-minus:before {
    background-color: #000000;
}

.food-item .stepper-button-plus:after,
.food-item .stepper-button-minus:after {
    width: 12px;
}

.food-item .stepper-button-plus:before {
    height: 12px;
}

.food-item .stepper-input-wrap,
.food-item .stepper-value {
    border: none;
}

.food-item .stepper .stepper-input-wrap input,
.food-item .stepper-value {
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: 35px;
}

.vertical-sheet {
    --f7-sheet-border-color: transparent;
    border-radius: 15px 15px 0 0;
    height: auto;
    max-height: 80%;
    overflow: hidden;
}

.swipe-handler {
    height: 16px;
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    background: #fff;
    cursor: pointer;
    z-index: 10;
}

.swipe-handler:after {
    content: '';
    width: 36px;
    height: 6px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -18px;
    margin-top: -3px;
    border-radius: 3px;
    background: #666;
}

.food-basket {
    max-height: 80vh;
    height: auto;
}

.fixed-height {
    height: 80vh;
}

.food-basket-link {
    position: fixed;
    width: calc(100% - var(--f7-block-padding-horizontal) - var(--f7-safe-area-left) - var(--f7-block-padding-horizontal) - var(--f7-safe-area-right));
    height: 50px;
    line-height: 50px;
    background: var(--active-color);
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: -0.41px;
    backdrop-filter: blur(25px);
    border-radius: 5px;
    margin-bottom: 20px;
}

.food-content,
.content-with-categories {
    padding-bottom: calc(var(--f7-page-toolbar-bottom-offset, 0px) + var(--f7-safe-area-bottom) + var(--f7-page-content-extra-padding-bottom, 0px) + 65px);
}

.food-basket-title {
    font-weight: bold;
    font-size: 21px;
    line-height: 26px;
    padding: 0 0 20px;
    position: relative;
    z-index: 20;
    background: #fff;
}

.food-basket-list {
    padding-bottom: 120px;
    margin-top: 0;
}

.fixed-height .food-basket-list {
    padding-bottom: 173px;
}

.food-basket-list .food-basket-item {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    border-bottom: 1px solid rgba(120, 120, 128, 0.2);
    margin-bottom: 16px;
    padding-bottom: 16px;
}

.food-basket-list .food-basket-item:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.food-basket-list .food-basket-item .left {
    width: 91px;
    height: 72px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 12px;
}

.food-basket-list .food-basket-item .left .img {
    width: 100%;
    height: 100%;
}

.food-basket-list .food-basket-item .left .img img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.food-basket-list .food-basket-item .right {
    width: calc(100% - 91px - 36px - 24px);
    margin-right: 12px;
}

.food-basket-list .food-basket-item .right .name {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #0a191e;
    margin-bottom: 4px;
}

.food-basket-list .food-basket-item .right .info {
    font-size: 15px;
    line-height: 18px;
    color: #0a191e;
}

.food-basket-list .food-basket-item .right .info span {
    color: #848484;
}

.food-basket-list .food-basket-item .delete {
    width: 36px;
    /*height: 36px;*/
}

.food-basket-list .food-basket-item .delete button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
}

.food-basket-result {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    bottom: 0;
    width: 100%;
    background: #fff;
    padding-top: var(--f7-block-margin-vertical);
    padding-bottom: calc(var(--f7-block-margin-vertical) * 2 - 10px);
    margin: 0;
}

.food-basket-result-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.food-basket-result .left {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #000000;
    width: calc(100% - 235px);
}

.food-basket-result .right {
    width: 225px;
}

.food-basket-result .right .button,
.order-sent-success-modal .button {
    width: 100%;
    height: 50px;
    background: var(--active-color);
    backdrop-filter: blur(25px);
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.41px;
    color: #ffffff;
}

.food-basket-result .amount_notification {
    color: red;
    margin-bottom: 10px;
}

.order-sent-success-modal .button a {
    color: #ffffff;
}

.order-sent-success-modal .page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 50vh;
    height: 382px;
    max-width: 260px;
    width: 80%;
    margin: auto;
}

.order-sent-success-modal .page-content .img {
    margin: 0 0 14px;
}

.order-sent-success-modal .page-content .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.75px;
    color: #000000;
    margin: 0 0 8px;
}

.order-sent-success-modal .page-content .text {
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.408px;
    color: #1c2f30;
    margin: 0;
}

.ios .preloader-inner-line {
    background: #fff;
}

.md .preloader-inner circle {
    stroke: #fff;
}

.md .food-item .stepper-input-wrap {
    height: 30px;
}

.disabled-button {
    opacity: 0.6;
    pointer-events: none;
}

.update_app_link {
    display: none;
}

.images-list img {
    width: 100%;
}

.crop-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

#mainSlider {
    height: 60%;
    margin-bottom: 24px;
}

/*slider information page*/
.information-page-swiper {
    margin: 0;
    padding: 0;
    height: 360px;
    position: relative;
    overflow: hidden;
}

.information-page-swiper .swiper-slide {
    background: transparent;
    width: 100%;
    height: 100%;
}

.information-page-swiper .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.information-list .row {
    justify-content: flex-start;
}

.information-list .row .col {
    --f7-cols-per-row: 4 !important;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    padding: 0 5px;
}

.information-list .row .col>a {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.information-list .col .information-tab-item-img {
    display: block;
    margin-bottom: 8px;
    width: 60px;
    height: 60px;
}

.information-list .col .information-tab-item-title {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;

    width: 100%;
    /* white-space: nowrap; */

    overflow: hidden;
    text-overflow: ellipsis;
}

.hidden {
    visibility: hidden;
    height: 0;
    padding: 0;
    margin: 0;
}

/*login*/

.md .item-input:not(.item-input-outline) .item-input-wrap:after,
.md .input:not(.input-outline):after {
    content: none;
}

.btn {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ce5c38;
    border-radius: 40px;
    border: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: #ffffff;
}

.btn:disabled {
    background: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.5);
}

.btn.gray {
    background: #f0f0f0;
    color: rgba(0, 0, 0, 0.85);
}

.btn:active:not(:disabled) {
    opacity: 0.7;
}

.item-input-wrap {
    position: relative;
}

.item-input-wrap.fio_auth {
    margin-bottom: 20px;
}

.item-input-wrap .input-clear-button {
    top: 0;
    right: 0;
    width: 54px;
    height: 54px;
    margin: 0;
}

.item-input-wrap .input-clear-button:before {
    content: none;
}

.item-input-wrap .input-clear-button:after {
    content: ' ';
    display: block;
    background: url('../static/svg/clear-input.svg') no-repeat center center;
}

.item-input-wrap .item-input-error-message {
    margin: 8px 0 0;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.078px;
    color: #ff4d4f;
}

.auth-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.auth-content .img {
    height: 55%;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
}

.auth-content .img img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.auth-content .img .bg {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: -1px;
    left: 0;
    background: linear-gradient(0, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.auth-content .title {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2rem;
    text-align: center;
    letter-spacing: 0.87px;
    color: #000000;
    margin-bottom: 2rem;
}

.auth-content .buttons {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.auth-content .buttons a:not(:last-child) {
    margin-bottom: 12px;
}

.auth-content .booking-link {
    margin-top: 1.75rem;
    text-align: center;
    width: 100%;
}

.auth-content .booking-link a {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    text-decoration: underline;
    color: rgba(0, 0, 0, 0.85);
}

.privacypolicy-link {
    text-decoration: underline;
}

.login-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}

.login-content .description-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;
    flex-shrink: 0;
}

.login-content .description-block .title {
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.374px;
    color: rgba(0, 0, 0, 0.85);
    text-align: center;
    margin-bottom: 6px;
}

.login-content .description-block .subtitle {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
}

.login-content .form-block {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.login-content .form-block input {
    height: var(--f7-input-height);
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 100px;
    padding: 0 54px 0 16px;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: rgba(0, 0, 0, 0.85);
}

.login-content .form-block .fio_input {
    border: none;
}

.list .fio_auth_list::before {
    content: none;
}

.list .fio_auth_list::after {
    content: none;
}

.login-content .form-block input:disabled {
    background: rgba(0, 0, 0, 0.1);
    opacity: 0.6;
}

.login-content .form-block input:not(:disabled):active,
.login-content .form-block input.input-focused:not(:disabled) {
    border-color: #2f54eb;
}

.login-content .form-block input.input-invalid:not(:disabled) {
    background: #fff1f0;
    border: 1px solid #ff4d4f;
}

.login-content .form-block .buttons {
    margin-top: auto;
    border-top: 1px solid #d9d9d9;
    padding-top: 11px;
}

.login-content .confirm-block {
    display: flex;
    flex-direction: column;
    margin: 24px 0 37px;
    align-items: center;
    width: 100%;
}

.login-content .confirm-block .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.8px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 27px;
}

.login-content .confirm-block .description {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: rgba(0, 0, 0, 0.65);
}

.login-content .confirm-block .description span {
    color: #8451ef;
    border: 1px dashed #8451ef;
    border-radius: 8px;
    padding: 3px 6px;
}

.login-content .confirm-input-block {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.login-content .confirm-input-block .input-block {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.login-content .confirm-input-block .input-block input:not(:last-child) {
    margin-right: 11px;
}

.login-content .confirm-input-block .input-block input {
    width: 49px;
    height: 49px;
    background: #f0f0f0;
    border: none;
    text-align: center;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);
}

.login-content .confirm-input-block .input-block input.input-focused {
    border: 1px solid #8451ef;
}

.login-content .confirm-input-block .buttons {
    margin-top: auto;
    border-top: 1px solid #d9d9d9;
    padding-top: 11px;
}

.user-info-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2px;
}

.user-info-block .img {
    width: 92px;
    height: 92px;
    border-radius: 50%;
    margin-bottom: 11px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.85);
    font-size: 30px;
    font-weight: bold;
    color: #fff;
}

.user-info-block .img img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.user-info-block .name {
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
    margin-bottom: 8px;
    text-align: center;
}

.user-info-block .link {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
}

.loyalty-block {
    background: #ffffff;
    border-radius: 18px;
    padding: 16px;
    position: relative;
}

.loyalty-block .blur {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    backdrop-filter: blur(7px);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 18px;
    z-index: 9;
    border: 1px solid #d9d9d9;
}

.loyalty-block.loyalty-status-2 {
    background: conic-gradient(from 180deg at 48.5% 50%,
            #f7f8f9 -46.16deg,
            #d6dfe6 130.63deg,
            #fafbfc 133.38deg,
            #d7e0e7 311.43deg,
            #f7f8f9 313.84deg,
            #d6dfe6 490.63deg);
}

.loyalty-block.loyalty-status-3 {
    background: conic-gradient(from 180.96deg at 48.5% 50%,
            #925a08 -28.12deg,
            #e3a455 26.25deg,
            #f6dba6 88.12deg,
            #ffebc4 156.58deg,
            #f0be79 191.74deg,
            #8f653b 213.59deg,
            #673d22 225.95deg,
            #ba7f3b 255.19deg,
            #eebc70 278.6deg,
            #925a08 331.88deg,
            #e3a455 386.25deg);
}

.loyalty-block.loyalty-status-4 {
    background: conic-gradient(from 180deg at 48.5% 50%,
            #b8c9d3 -28.12deg,
            #fbfbfd 26.25deg,
            #c8d4da 88.12deg,
            #ffffff 156.58deg,
            #aec0ce 191.74deg,
            #e3e9ee 237.13deg,
            #fafbfc 255.19deg,
            #d6dfe6 310.11deg,
            #b8c9d3 331.88deg,
            #fbfbfd 386.25deg);
}

.loyalty-block .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
    z-index: 10;
}

.loyalty-block .content .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: rgba(0, 0, 0, 0.85);
    text-transform: uppercase;
}

.loyalty-block .content .description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: rgba(0, 0, 0, 0.65);
}

.profile-info-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    box-shadow:
        0 6px 25px -6px rgba(132, 81, 239, 0.12),
        0 10px 32px -4px rgba(132, 81, 239, 0.1);
    border-radius: 18px;
    padding: 16px;
    margin-bottom: 15px;
}

.profile-info-block.log-history {
    align-items: center;
}

.profile-info-block .left .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 4px;
}

.profile-info-block .left .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.32px;
    color: rgba(0, 0, 0, 0.65);
}

.profile-info-block .right .btn {
    padding: 6px 10px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.154px;
    height: auto;
    width: auto;
    min-width: 80px;
}

.loyalty-detail-img {
    display: block;
    width: 100%;
    height: 62vh;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
}

.loyalty-detail-img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.loyalty-detail-close {
    position: absolute;
    z-index: 20;
}

.loyalty-detail-info {
    position: relative;
    top: 50vh;
    background: #fff;
    padding: 24px 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    z-index: 10;
    color: #000000;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
}

.loyalty-detail-info>.block {
    margin: 0;
}

.loyalty-detail-info h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    margin: 0 0 34px;
}

.loyalty-detail-info h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    margin: 0 0 16px;
}

.loyalty-detail-info h3.extra-margin-bottom {
    margin-bottom: 24px;
}

.loyalty-detail-info h4 {
    font-weight: 600;
    margin: 0 0 16px;
}

.loyalty-detail-info ul {
    margin: 0 0 24px;
    list-style-type: none;
    padding: 0;
}

.loyalty-detail-info ul:last-child {
    margin: 0;
}

.loyalty-detail-info ul li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 6px;
    margin-bottom: 12px;
}

.loyalty-detail-info ul li:last-child {
    margin-bottom: 0;
}

.loyalty-detail-info ul li:before {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url('../static/ul-marker.svg') no-repeat 0 0;
    flex-shrink: 0;
}

.loyalty-detail-info-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 34px;
}

.loyalty-detail-info-block>div {
    padding: 16px;
    background: #f6f7f8;
    border-radius: 18px;
    width: calc(50% - 5px);
    box-sizing: border-box;
}

.loyalty-detail-info-block>div.full-width {
    width: 100%;
}

.loyalty-detail-info-block>div .title {
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 4px;
}

.loyalty-detail-info-block>div .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.65);
}

.profile-edit-content .form-block input {
    height: 58px;
    border-radius: 12px;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.408px;
    color: rgba(0, 0, 0, 0.85);
    padding: 22px 45px 0 16px;
}

.profile-edit-content .form-block .item-label {
    position: absolute;
    top: 8px;
    left: 30px;
    z-index: 10;

    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #8e8e93;
}

.profile-edit-content .item-input-wrap .input-clear-button {
    width: 58px;
    height: 58px;
}

.profile-edit-content .item-input-wrap .item-input-error-message {
    position: absolute;
}

.img.info-user-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 53px;
    border: 1px solid #f3e0ec;
    border-radius: 50%;
}

.img.info-user-img img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
}

.md .navbar-inner.information-header {
    justify-content: space-between;
    padding-right: 13px;
}

.information {
    background: white;
}

.navbar-inner.information-header.information-title {
    margin-top: 16px;
    padding-left: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left));
    padding-right: calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-right));
}

.title-info {
    font-size: 28px;
    font-weight: bold;
}

.btn-accrod-pay {
    outline: none;
    border: none;
    background-color: transparent;
    padding: 0;
}

.accordion-list .accordion-item {
    background: #f5f5f5;
}

.accordion-list .accordion-item .item-title {
    color: black;
}

.accordion-list .accordion-item .data-table .first-cell {
    color: black;
}

.accordion-list .accordion-item .data-table .double-cell {
    color: black;
}

.accordion-list ul {
    background-color: var(--f7-page-bg-color);
}

.notification-info .title {
    font-weight: 600;
    font-size: 17px;
}

.notification-info .service-block {
    padding: 16px;
    background-color: rgba(241, 228, 243);
}

#close-notification-btn {
    position: absolute;
    top: 0;
    right: 0;
}

.notification-info {
    font-size: 13px;
    margin-bottom: 20px;
    word-break: break-word;
}

.notification-info #close-notification-info-btn {
    position: absolute;
    top: 0;
    right: 15px;
}

.block-room-info {
    margin: 10px 0;
}

.notification.modal-in {
    background-color: rgb(241, 228, 243);
}

.notification.modal-in .notification-title,
.notification.modal-in .notification-text {
    word-break: break-word;
}

.notification.modal-in .notification-content {
    margin-top: 5px;
}

/* auth popup */
.popup {
    width: 95%;
    left: 10px;
}

.popup.auth {
    height: 350px;
    top: auto;

    bottom: 20px;
    border-radius: 36px;
    padding: 16px;
}

.popup.auth .auth-header {
    margin-bottom: 24px;
}

.popup.auth .auth-text {
    margin-top: 16px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5px;
}

.popup.auth .auth-sub-text {
    display: block;
    width: 250px;
    font-size: 17px;
    color: #8a8a8d;
    font-weight: 400;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.popup.auth .buttons .btn {
    position: relative;
    margin-bottom: 16px;
}

.popup.auth .buttons .btn .auth-icon {
    position: absolute;
    left: 15px;
}

.popup.auth .auth-popup-close {
    display: block;
    position: absolute;
    top: 18px;
    right: 18px;
}

.btn.auth-popup {
    display: flex;
    align-items: center;
    position: relative;
}

.btn .auth_icon {
    position: absolute;
    left: 25px;
}

/* review popup */
.popup.review {
    width: 100%;
    left: 0;
    height: 535px;
    top: auto;
    bottom: 0;
    border-radius: 18px 18px 0 0;
}

.review-title {
    position: relative;
    font-size: 24px;
    font-weight: 650;
    letter-spacing: -0.4px;
    margin-bottom: 0;
}

.review-subtitle {
    font-size: 16px;
    margin-top: 6px;
    margin-bottom: 35px;
    color: #52525b;
}

.review-grade-text {
    font-weight: 600;
}

.review-grage-stars,
.review-stars {
    display: flex;
    justify-content: space-around;
}

.review-text {
    width: 95%;
    height: 140px;
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 55px;
    padding: 10px;
    font-size: 12px;
    border: 2px solid #f4f4f5;
    border-radius: 12px;
}

.review-text:focus {
    border-color: var(--active-color);
}

.review-send-btn {
    /* background: #5B2DE8; */
    background: lightgray;
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
    outline: none;
    border: none;
    color: white;
    font-weight: 600;
}

.review-close {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 16px;
}

.gray-stars {
    fill: #f2f2f2;
}

.yellow-stars {
    fill: gold;
}

.review-btn {
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 13px;
    text-transform: unset;
    margin-bottom: 10px;
}

.kaizen-review-complit-btn {
    background-color: lightgray;
}

.disabled-room-lock {
    background: lightgray;
}

#disabled-room-text {
    color: white;
}

.disabled-svg-lock {
    display: block;
    fill: red;
}

.btn-call {
    background-color: green;
    margin-top: 25px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 30px;
    border-radius: 25px;
    border: none;
    color: white;
    font-size: 16px;
}

.chat-badge {
    position: absolute;
    left: 55%;
    background: red;
}

@media screen and (min-width: 768px) {
    .toolbar .toolbar-inner .media-badge {
        display: flex;
        flex-direction: column;
    }

    .chat-badge {
        left: 50%;
        top: 2%;
    }
}

.login-phone-subtitile {
    margin-top: 80px;
    color: #111111 !important;
    padding: 0 50px;
}

.login-phone-number-box {
    font-size: 24px;
    border: dashed 1px rgba(0, 0, 0, 0.45);
    border-radius: 10px;
    padding: 12px 0;
    margin: 0 55px;
}

.login-phone-number-box svg {
    margin-right: 15px;
}

.buttons-call-auth {
    margin-top: auto;
    border-top: 1px solid #d9d9d9;
    padding-top: 11px;
}

.login-phone-number-box div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-new-number {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#send-again-timer {
    display: none;
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
    color: gray;
}

.login-new-number button {
    font-weight: 500;
    font-size: 16px;
    color: #1f59e6;
    background: none;
    border: none;
}

.not-active-number {
    color: lightgray;
}

.coworking-tariff-block {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    padding: 16px;
    gap: 16px;
    margin-bottom: 16px;
}

.coworking-tariff-block .left {
    width: 40px;
    flex-shrink: 0;
}

.coworking-tariff-block .left img {
    width: 100%;
    display: block;
}

.coworking-tariff-block .right {
    width: 100%;
}

.coworking-tariff-block .right .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #111111;
    margin-bottom: 4px;
}

.coworking-tariff-block .right .description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #96959a;
    margin-bottom: 14px;
}

.coworking-tariff-block .right .buttons-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.coworking-tariff-block .right .buttons-block .btn {
    width: auto;
    white-space: nowrap;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
}

.btn.transparent-btn {
    background: transparent;
    color: #96959a;
}

.btn.violet-btn {
    background: #5b2de8;
    border-radius: 8px;
    padding: 0 16px;
    height: 36px;
}

.coworking-tariff-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
}

.coworking-tariff-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 16px;
    box-sizing: border-box;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #e4e4e5;
    padding: 18px 16px 16px;
}

.coworking-tariff-item.active {
    border-color: #5b2de8;
}

.coworking-tariff-item.history {
    border-color: #0000001a;
}

.history .info.processing {
    color: #ff9500;
    background: rgba(255, 153, 8, 0.1);
}

.coworking-tariff-item .left {
    width: 100%;
}

.coworking-tariff-item .right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    border: 1px solid #e4e4e5;
    border-radius: 50%;
    box-sizing: border-box;
}

.coworking-tariff-item.active .right {
    background: #5b2de8;
    border-color: #5b2de8;
}

.coworking-tariff-item.active .right:after {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
}

.coworking-tariff-item.history .history-date {
    color: #8a8a8d;
    font-size: 15px;
    font-weight: 500;
}

.coworking-tariff-item .title-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
}

.coworking-tariff-item .title {
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #8a8a8d;
    text-transform: uppercase;
    margin-bottom: 14px;
}

.coworking-tariff-item .title-block .title {
    margin-bottom: 0;
}

.coworking-tariff-item .days {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #fff;
    padding: 5px 8px;
    background: #5b2de8;
    border-radius: 20px;
}

.coworking-tariff-item .info-block {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-bottom: 6px;
}

.coworking-tariff-item .price {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #1e1e24;
}

.coworking-tariff-item .info {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #4db607;
    padding: 2px 8px;
    background: #e7f8dc;
    border-radius: 4px;
}

.coworking-tariff-item .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #8a8a8d;
}

.tariff-info-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
}

.coworking-tariff-services-list {
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding: 18px 16px;
    border-radius: 20px;
    border: 1px dashed rgba(0, 0, 0, 0.25);
    background: #fff;
}

.coworking-tariff-services-list>div {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #1e1e24;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
}

.coworking-tariff-services-list>div:before {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    flex-shrink: 0;
    background: url('../static/svg/list-item-no-checked.svg') no-repeat center center;
}

.coworking-tariff-services-list>div.checked:before {
    background-image: url('../static/svg/list-item-checked.svg');
}

.coworking-tariff-services-list .preloader-inner {
    display: none;
}

.coworking-purchase-block {
    margin-top: auto;
    border-top: 1px solid #e4e4e5;
    padding-top: 12px;
}

.page-flex {
    display: flex;
    flex-direction: column;
}

.page-flex.tariff-list {
    padding-bottom: 1px;
}

.preloader-block {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.pay-completed {
    background-color: #4cd964;
}

.office-title.title {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    font-weight: bold;
}

.office-title .sub-title {
    margin-left: 10px;
}

.history-btn {
    width: 52px;
    margin-left: auto;
    margin-right: 5px;
    padding: 8px 13px;
    border: none;
    border-radius: 100px;
    background: #ebecee;
}

.left.left-btn-tariff-item {
    margin-right: 0 !important;
}

.room-manager-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#view-home .loader-off .preloader-inner {
    display: none;
}

.page-content {
    background: #fff;
}

.ios-translucent-bars .toolbar,
.toolbar {
    background: #fff;
}

/*new shop*/

/* .category {} */

.category.shop {
    margin-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
}

.category.shop::-webkit-scrollbar {
    width: 0;
    display: none;
}

.category.shop .item {
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 17px;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;
}

.category.shop .item:last-child {
    margin-bottom: 0;
}

.category.shop .item:before,
.category.shop .item:after {
    height: 0;
}

.category.shop .item .left {
    width: 130px;
    height: 110px;
    border-radius: 10px;
    overflow: hidden;
}

.category.shop .item .left .img {
    width: 100%;
    height: 100%;
}

.category.shop .item .left .img img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.category.shop .item .right {
    width: calc(100% - 147px);
    height: 110px;
    box-sizing: border-box;
    padding: 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.category.shop .item .right .name {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #0a191e;
    margin-bottom: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.category.shop .item .right .info {
    font-size: 13px;
    line-height: 16px;
    margin: 8px 0;
}

.category.shop .item .right .info .gray {
    color: #959595;
}

.category.shop .item .stepper-button-minus,
.category.shop .item .stepper-button-plus {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background: #f5f5f5;
}

.category.shop .item .stepper-button-plus:after,
.category.shop .item .stepper-button-minus:after,
.category.shop .item .stepper-button-plus:before,
.category.shop .item .stepper-button-minus:before {
    background-color: #000000;
}

.category.shop .item .stepper-button-plus:after,
.category.shop .item .stepper-button-minus:after {
    width: 12px;
}

.category.shop .item .stepper-button-plus:before {
    height: 12px;
}

.category.shop .item .stepper-input-wrap,
.category.shop .item .stepper-value {
    border: none;
}

.category.shop .item .stepper .stepper-input-wrap input,
.category.shop .item .stepper-value {
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    width: 35px;
}

.md .category.shop .item .stepper-input-wrap {
    height: 30px;
}

.category.services {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
}

.category.services .item {
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    border-radius: 17px;
    padding: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;
}

.category.services .item:before,
.category.services .item:after {
    height: 0;
}

.category.services .item .left {
    width: 130px;
    height: 130px;
    border-radius: 10px;
    overflow: hidden;
}

.category.services .item.no-price .left {
    height: 110px;
}

.category.services .item .left .img {
    width: 100%;
    height: 100%;
}

.category.services .item .left .img img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.category.services .item .right {
    width: calc(100% - 147px);
    height: 130px;
    box-sizing: border-box;
    padding: 6px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.category.services .item.no-price .right {
    height: 110px;
}

.category.services .item .right .name {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #0a191e;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex-shrink: 0;
    margin-bottom: 2px;
}

.category.services .item .right .description {
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #8e8e93;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    flex-shrink: 0;
    margin-bottom: 4px;
}

.category.services .item .right .price {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #0a191e;
    flex-shrink: 0;
}

.category.services .item .right .detail {
    margin-top: auto;
    height: 30px;
    width: 100px;
    font-size: 14px;
    line-height: 18px;
}

.shop-services-item .img {
    display: block;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 16px;
}

.shop-services-item .img img {
    display: block;
    width: 100%;
    height: auto;
}

.shop-services-item .description {
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    color: #000;
    margin-bottom: 135px;
}

.shop-stepper-wrapper {
    background-color: #fff;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;
    width: 100%;
    position: fixed;
    z-index: 100;
    padding-top: 12px;
    border-top: 1px solid #b2b2b2;
}

.shop-stepper-wrapper .fab-purchase-button {
    width: 94%;
    margin-left: auto;
    margin-right: auto;
}

.shop-stepper.stepper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    height: 54px;
    width: 94%;
    margin-left: auto;
    margin-right: auto;
}

.shop-stepper.stepper .stepper-button-minus,
.shop-stepper.stepper .stepper-button-plus {
    width: 84px;
    height: 100%;
    flex-shrink: 0;
    border: none;
    background: rgba(192, 192, 194, 0.65);
    border-radius: 40px;
}

.shop-stepper.stepper .stepper-button-plus:after,
.shop-stepper.stepper .stepper-button-minus:after,
.shop-stepper.stepper .stepper-button-plus:before,
.shop-stepper.stepper .stepper-button-minus:before {
    background: #000;
}

.shop-stepper.stepper .stepper-input-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    border: none;
    font-size: 17px;
    font-weight: 500;
    line-height: 22px;
}

.shop-stepper.stepper .stepper-input-wrap input {
    text-align: left;
    color: #000;
    font-size: 17px;
    font-weight: 500;
    margin-left: 5px;
    width: 30px;
}

.fab-purchase-button {
    width: full;
    height: 54px;
    background: var(--active-color);
    color: #ffffff;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    font-family: inherit;
    backdrop-filter: blur(25px);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    transform: none;
    margin-top: 12px;
    border: none;
}

.fab-purchase-button span {
    position: absolute;
    right: 8px;
    display: inline-flex;
    padding: 8px 12px 8px 16px;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    gap: 8px;
    border-radius: 100px;
    background: rgba(255, 255, 255, 0.1);
}

.auth-content .title.auth-main-text {
    font-size: 25px;
    margin-bottom: 20px;
}

.mys-confidentiality-info {
    margin-top: 24px;
    margin-left: 65px;
    margin-right: 65px;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
}

.toolbar.tabbar.toolbar-top.row .shop-navigation {
    overflow-x: auto;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 0;

    margin-right: 5px;
    padding-right: 15px;
    padding-left: 13px;
    z-index: 10;
}

.toolbar.tabbar.toolbar-top.row::before {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    top: 96%;
    bottom: auto;
    height: 7px;
    pointer-events: none;
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0.25) 0%,
            rgba(0, 0, 0, 0.08) 40%,
            rgba(0, 0, 0, 0.04) 50%,
            rgba(0, 0, 0, 0) 90%,
            rgba(0, 0, 0, 0) 100%);
}

.toolbar.tabbar.toolbar-top.row .shop-navigation::-webkit-scrollbar {
    width: 0;
    display: none;
}

.toolbar.tabbar.toolbar-top.row .shop-navigation .tab-link-highlight {
    display: none;
}

.toolbar.tabbar.toolbar-top.row .shop-navigation a {
    display: block;
    width: auto;
    height: auto;
    flex-shrink: 0;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 14px;
}

.toolbar.tabbar.toolbar-top.row .shop-navigation .tab-link-active {
    background-color: rgba(206, 92, 56, 0.2);
    height: auto;
    padding: 5px 10px;
    border-radius: 17px;
    line-height: 20px;
}

.page-content.about-info-bg {
    background-color: #1c2f30;
    /* background-image: url('../static/landing_2.jpeg');
    background-size: cover;
    background-size: contain;
    background-repeat: no-repeat; */
}

.page-content.page-content.about-info-bg .content-description {
    overflow: auto;
    padding-bottom: 20px;
}

.page-content.page-content.about-info-bg .content-description::-webkit-scrollbar {
    width: 0;
    display: none;
}

#feedback-form .block-header {
    color: rgba(0, 0, 0, 0.85);
}

/* room slider */
#img-swiper {
    height: 300px;
}

#room-photo-js {
    height: 100%;
    object-fit: cover;
}

.ms-user-room-number {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 80px;

    margin-bottom: 12px;
    padding: 16px;

    border: 1px solid #e3e3e3;
    border-radius: 17px;

    overflow: hidden;

    background: #ffffff;
}

.ms-user-room-number-box {
    display: flex;
}

.ms-user-room-number-box svg {
    margin-right: 16px;
}

.ms-user-room-number p {
    margin-bottom: 5px;
    margin-top: 0;

    font-size: 24px;
    font-weight: bold;
}

.ms-user-room-number span {
    font-size: 30px;

    color: var(--active-color)
}

.ms-user-room-number-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.room-manage-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.room-manage-block .service-block {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.room-manage-block .service-block .service-block-content .content {
    justify-content: center;
}

.room-manage-block .service-block .service-block-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 2;
}

.ms-link-block {
    width: 100%;
    display: flex;
    justify-content: space-around;
}